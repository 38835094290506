import React, {
	FunctionComponent,
	useEffect,
	useState,
	createContext,
} from "react";
import { StripeSku } from "../items/Items";

export type CartSubscriber = (cartItems: CartLineItem[]) => void;

const LOCAL_STORAGE_NAME = "cart";

interface CartLineItem {
	item: StripeSku;
	quantity: number;
}

export class Cart {
	private _items: CartLineItem[] = [];

	private _subscriptions = new Set<CartSubscriber>();
	private _isInstantiated = false;

	get items() {
		return [...this._items];
	}

	init() {
		if (!this._isInstantiated) {
			this._isInstantiated = true;
			const savedCart = localStorage.getItem(LOCAL_STORAGE_NAME);
			if (savedCart) {
				this._items = JSON.parse(savedCart);
				this._propagateChanges();
			}
		}
	}

	add(newItem: StripeSku, quantity = 1) {
		const existingItem = this.items.find(({ item }) => item.id === newItem.id);

		if (existingItem) {
			existingItem.quantity += quantity;
		} else {
			this._items.push({
				item: newItem,
				quantity,
			});
			this._propagateChanges();
		}
	}

	remove(skuToRemove: StripeSku) {
		const itemIndex = this.items.findIndex(
			({ item }) => item.id === skuToRemove.id
		);
		if (itemIndex >= 0) {
			this._items.splice(itemIndex, 1);
			this._propagateChanges();
		}
	}

	reset() {
		this._items = [];
		this._propagateChanges();
	}

	subscribe(subscriber: CartSubscriber) {
		this._subscriptions.add(subscriber);
		return () => this._subscriptions.delete(subscriber);
	}

	private _propagateChanges() {
		const cartArray = this.items;
		Array.from(this._subscriptions).forEach(subscriber =>
			subscriber(cartArray)
		);
		localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(cartArray));
	}
}

const cart = new Cart();

export const CartContext = createContext<{
	items: CartLineItem[];
	cart: Cart;
}>({
	items: cart.items,
	cart,
});

export const CartProvider: FunctionComponent<{}> = ({ children }) => {
	const [items, setCartState] = useState(cart.items);
	useEffect(() => {
		const cartUnsubscribe = cart.subscribe(setCartState);
		cart.init();
		return () => cartUnsubscribe();
	}, []);
	return (
		<CartContext.Provider
			value={{
				items,
				cart,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};
