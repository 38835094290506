import netlifyIdentity, { User as NetlifyUser } from "netlify-identity-widget";

type OnLoginCallback = (user: NetlifyUser) => void;
const loginCallbacks = new Set<OnLoginCallback>();

netlifyIdentity.on("login", user =>
	Array.from(loginCallbacks).forEach(cb => cb(user))
);

export function onLogin(cb: OnLoginCallback): () => void {
	loginCallbacks.add(cb);
	return () => loginCallbacks.delete(cb);
}

type OnLogoutCallback = () => void;
const logOutCallbacks = new Set<OnLogoutCallback>();

netlifyIdentity.on("logout", () =>
	Array.from(logOutCallbacks).forEach(cb => cb())
);

export function onLogout(cb: OnLogoutCallback): () => void {
	logOutCallbacks.add(cb);
	return () => logOutCallbacks.delete(cb);
}
