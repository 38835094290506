import React, {
	FunctionComponent,
	createContext,
	useCallback,
	useEffect,
	useState,
} from "react";
import netlifyIdentity, { User as NetlifyUser } from "netlify-identity-widget";
import { onLogin, onLogout } from "./netlifyIdentityOnWrappers";

export interface IdentityUser {
	contact: { fullName: string; email: string };
	id: string;
}

// const isBrowser = () => typeof window !== "undefined";

function mapUser(netlifyUser: NetlifyUser): IdentityUser {
	return {
		contact: {
			fullName: netlifyUser.user_metadata.full_name,
			email: netlifyUser.email,
		},
		id: netlifyUser.email,
	};
}

type IdentityUserType = IdentityUser | null;

// persist the user between instances of Identity
let persistantUser: IdentityUserType = null;

export const IdentityUserContext = createContext<IdentityUserType>(null);

function useIdentity() {
	const [identityUser, setIdentityUser] = useState<IdentityUserType>(
		persistantUser
	);
	useEffect(() => {
		if (netlifyIdentity.currentUser()) {
			setIdentityUser(mapUser(netlifyIdentity.currentUser()));
		}
		const cleanups = [
			onLogin(user => setIdentityUser(mapUser(user))),
			onLogout(() => setIdentityUser(null)),
		];

		return () => {
			cleanups.forEach(cb => cb());
		};
	}, []);
	persistantUser = identityUser;
	return identityUser;
}

export const Identity: FunctionComponent<{}> = ({ children }) => {
	const identityUser = useIdentity();
	return (
		<IdentityUserContext.Provider value={identityUser}>
			{children}
		</IdentityUserContext.Provider>
	);
};
