import React from "react";
import FontIcon from "react-md/lib/FontIcons";
import { Link } from "gatsby";

function GetNavList(config) {
	const NavList = [
		{
			primaryText: "Home",
			leftIcon: <FontIcon>home</FontIcon>,
			component: Link,
			to: "/",
		},
		{
			divider: true,
		},
		{
			primaryText: "Payments",
			leftIcon: <FontIcon>store</FontIcon>,
			component: Link,
			to: "/payments",
		},
		{
			divider: true,
		},
		{
			primaryText: "Cart",
			leftIcon: <FontIcon>shopping_cart</FontIcon>,
			component: Link,
			to: "/cart",
		},
		{
			divider: true,
		},
		{
			primaryText: "Text Messages",
			leftIcon: <FontIcon>message</FontIcon>,
			component: Link,
			to: "/alerts",
		},
		{
			divider: true,
		},
		{
			primaryText: "FAQs",
			leftIcon: <FontIcon>help</FontIcon>,
			component: Link,
			to: "/faqs",
		},
	];

	/*if (config.userLinks) {
		config.userLinks.forEach(link => {
			NavList.push({
				primaryText: link.label,
				leftIcon: <FontIcon forceSize iconClassName={link.iconClassName} />,
				component: "a",
				href: link.url,
			});
		});
	}*/

	return NavList;
}
export default GetNavList;
