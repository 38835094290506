import React, { FunctionComponent, useState, useContext } from "react";
import { Link } from "gatsby";

import Layout from "../layout";
import SEO from "../components/seo";
import {
	Grid,
	Cell,
	Card,
	CardTitle,
	Button,
	CardActions,
	CardText,
} from "react-md";

const CartPage: FunctionComponent<{}> = () => {
	return (
		<Layout title="Text Alerts">
			<SEO title="Text Alerts" />
			<Grid>
				<Cell desktopOrder={2}>
					<Card>
						<CardTitle title="Get Text Message Alerts" />
						<CardText>
							Sign up to get text message alerts. Alerts are sent out when the
							bus might be late, or if there is a simcha etc.
						</CardText>
						<CardActions centered>
							<Button
								raised
								primary
								href="https://www.remind.com/join/kolelbus"
							>
								Sign Up
							</Button>
						</CardActions>
					</Card>
				</Cell>
				<Cell desktopOrder={1}>
					<Card>
						<CardTitle title="Recent Messages:" />
						<CardText>
							<iframe
								src="https://widgets.remind.com/widget?height=500&join=false&token=df6730805d2b0137f03b0242ac110003"
								id="remind101-widget-0"
								className="remind101-messages"
								frameBorder="0"
								style={{ border: 0 }}
								title="Remind messages"
								width="100%"
								height="400px"
							/>
						</CardText>
					</Card>
				</Cell>
			</Grid>
		</Layout>
	);
};

export default CartPage;
